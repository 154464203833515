<template>
  <div id="abc" class="select-main">
    <div class="header">
      <el-page-header @back="goBack"></el-page-header>
      <div id="selected-num" style="">已选（{{this.selectedLessons.length}}）</div>
    </div>
    <div style="clear: both"></div>
    <div class="div-list">
      <loading :loading="loading" text="搜索中"></loading>
      <el-form ref="form"    label-width="100px">
        <el-form-item label="课时ID" label-width="60px" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="id" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="课时名称" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="text"  v-model="name" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
        <el-form-item></el-form-item>
      </el-form>
      <div id="divList" class="layui-card-body">
        <el-table border :data="lessons" style="width: 100%" v-show="tableShow">
          <el-table-column prop="id" label="ID" align="center" width="70"></el-table-column>
          <el-table-column prop="name" label="名称" align="left"></el-table-column>
          <el-table-column prop="duration" label="视频时长" align="center" width="80"></el-table-column>
          <el-table-column prop="typeName" label="类型" align="center" width="80"></el-table-column>
          <el-table-column prop="sort" label="排序" align="center" width="80"></el-table-column>
          <el-table-column prop="createdOn" label="创建日期" align="center" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="70" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickSelected(scope.row)" type="text" size="small" v-show="!checkIsSelected(scope.row)" >选择</el-button><el-button @click="handleClickUnSelected(scope.row)" type="text" size="small" v-show="checkIsSelected(scope.row)" style="color: #DD691D" >已选</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="pageCurrentChange"
            style="text-align: center;padding: 20px;"
            v-show="tableShow"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {UrlEnum, getLocalProjectId} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";
export default {
  name: "SelectLesson",
  components: {Loading},
  data() {
    return {
      loading:false,
      lessons:[],
      total:0,
      page:1,
      size:10,
      id:'',
      name:'',
      selectedLessons:[],
      tableShow:false
    }
  },
  methods:{
    handleClickSelected(row){
      this.selectedLessons.push({id:row.id,name:row.name});
    },
    handleClickUnSelected(row){
      for(let i=0;i<this.selectedLessons.length;i++){
        if(row.id == this.selectedLessons[i].id){
          this.selectedLessons.splice(i,1);
        }
      }
    },
    goBack() {
      this.$route.params.selectedLessons = this.selectedLessons;
      this.$router.back();
    },
    checkIsSelected(row){
      for(let i=0; i<this.selectedLessons.length; i++){
        if(this.selectedLessons[i].id == row.id){
          return true;
        }
      }
      return false;
    },

    pageCurrentChange(val,e,form,jobExpLabel,pageform,searchVal) {
      this.page = val;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.LESSON;
      url+="?page="+this.page;
      url+="&size="+this.size;
      url+="&projectId="+getLocalProjectId();
      if(this.id != ''){
        url += "&id="+this.id;
      }
      if(this.name != ''){
        url += "&name="+this.name;
      }

      console.log(url);
      return url;
    },
    reset(){
      this.page=1;
      this.id='';
      this.name='';
      this.getData();
    },
    getData() {
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        this.lessons = res.data.list;
        this.tableShow=true;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    }
  },
  mounted() {
    this.selectedLessons = this.$route.params.selectedLessons;
    this.getData();
  }
}
</script>

<style>
html,body{
  height: 100%;
  background: white;
  overflow: hidden;
}
.iframe-tree{
  width:100%;
  height: 100%;
  border: 0px;
  overflow:hidden
}
.div-list{
  position: relative;
  background: white;
  padding:10px;
  height: calc(100% - 40px);
  margin-top:40px;
  overflow: auto;
}

.header{
  top:0px;
  height: 45px;
  width: calc(100% - 30px);
  background-color: #ffffff;
  position: absolute;
}
.el-page-header {
  display: flex;
  line-height: 45px;
  margin-left: 20px;
  float: left;
}
.el-page-header__left {
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  position: absolute;
  width: 100%;
  height: 45px !important;
  line-height: 45px !important;
}
.select-main{
  height: 100%;
}
#selected-num{
  float: right;
  height: 45px;
  line-height: 45px;
  color: #DD691D;
  font-size: 14px;
  font-weight: bold;
}
</style>
